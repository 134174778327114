import Header from '../components/Header'
import Meta from '../components/Meta'
import Body from '../components/Body'

const Home = () => {
  // page content
  const pageTitle = 'Cinema-V'
  const pageDescription = 'Welcome to Cinema-V'

  return (
    <div>
      <Meta title={pageTitle}/>
      <Header head={pageTitle} description={pageDescription} />
      <Body title={"Welcome"} description="We are currently migrating our website."/>
    </div>
  )
}

export default Home
